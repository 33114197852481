import React from 'react';
import { Root, Routes } from 'react-static';
import { ThemeProvider } from 'styled-components';
import { modularScale } from 'polished';

const theme = {
  acColor: '#cc6900',
  bgColor: '#3f4447',
  fgColor: '#333333',
  textFontFamily: 'pelago',
  textFontWeight: '400',
  headFontFamily: 'tablet-gothic-condensed',
  headFontWeight: '700',
  fontSize: (steps) => modularScale(steps,
    '1.2rem',
    'perfectFourth'),
};

class App extends React.Component {
  render() {
    return (
      <Root>
        <ThemeProvider theme={theme}>
          <Routes/>
        </ThemeProvider>
      </Root>
    );
  }
}

export default App;
